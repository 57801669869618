const PRIVATE_OFFICES_ENABLED =
  import.meta.env.VITE_PRIVATE_OFFICES_ENABLED === "true";

const RESOURCE_TYPES = {
  HOT_DESK: import.meta.env.VITE_RESOURCE_TYPE_HOT_DESK,
  MEETING_ROOM: import.meta.env.VITE_RESOURCE_TYPE_MEETING_ROOM,
  PRIVATE_OFFICE: import.meta.env.VITE_RESOURCE_TYPE_PRIVATE_OFFICE,
  EVENT_SPACE: import.meta.env.VITE_RESOURCE_TYPE_EVENT_SPACE,
  HOT_DESK: import.meta.env.VITE_RESOURCE_TYPE_HOT_DESK,
};

const IS_HD_RESOURCES_ENABLED =
  import.meta.env.VITE_IS_HD_RESOURCES_ENABLED === "true";

export { PRIVATE_OFFICES_ENABLED, RESOURCE_TYPES, IS_HD_RESOURCES_ENABLED };
